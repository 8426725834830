import questionApi from './question.api';

export default {

    /**
     * @desc Get question
     * @returns {Promise<void>}
     * @param query
     */
    async getQuestion(query = null) {
        return await questionApi.getQuestion(query);
    },

    /**
     * @desc Get questions
     * @returns {Promise<void>}
     * @param query
     */
    async getQuestions(query = null) {
        return await questionApi.getQuestions(query);
    },

    /**
     * @desc Update question
     * @returns {Promise<void>}
     * @param id
     * @param payload
     */
    async updateQuestion(id, payload) {
        return await questionApi.updateQuestion(id, payload);
    },

    /**
     * @desc Store questions
     * @returns {Promise<void>}
     * @param payload
     */
    async storeQuestion(payload) {
        return await questionApi.storeQuestion(payload);
    },

    /**
     * @desc Delete questions
     *
     * @returns {Promise<void>}
     * @param id
     */
    async deleteQuestion(id) {
        return await questionApi.deleteQuestion(id);
    },

};
