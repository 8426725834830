import { useState } from 'react';

export default function useAuth() {
    /**
     * @desc Get token
     * @returns {*}
     */
    const getToken = () => {
        const tokenString = sessionStorage.getItem('token');
        return JSON.parse(tokenString);
    };

    /**
     * @desc Get User
     * @returns {any}
     */
    const getUser = () => {
        const userString = sessionStorage.getItem('user');
        return JSON.parse(userString);
    };

    const [token, setToken] = useState(getToken());
    const [user, setUser] = useState(getUser());

    /**
     * @desc Save token
     * @param userToken
     */
    const saveToken = userToken => {
        sessionStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken);
    };

    /**
     * @desc Save user
     * @param userObject
     */
    const saveUser = userObject => {
        sessionStorage.setItem('user', JSON.stringify(userObject));
        setUser(userObject);
    };

    /**
     * @desc Logout
     */
    const logout = () => {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('user')
        window.location.replace('/login')
    }

    return {
        setToken: saveToken,
        token,
        setUser:saveUser,
        user,
        logout
    }
}