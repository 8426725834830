import {Dialog, Transition} from "@headlessui/react";
import {Fragment, useState, useEffect} from "react";
import questionServices from "../services/question.services";
import EventBus from "../../../utility/EventBus";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

function EditQuestionDialog(props) {
    let [selectedQuestion, setSelectedQuestion] = useState(props.question)
    let [isLoading, setIsLoading] = useState(false)

    const  modules  = {
        toolbar: [
            ["bold", "underline", "link",{ list:  "ordered" }, { list:  "bullet" }],
        ],
    };

    const languages = [
        {
            key: 'en',
            value: 'Inglese'
        },
        {
            key: 'it',
            value: 'Italiano'
        },
        {
            key: 'fr',
            value: 'Francese'
        },
        {
            key: 'de',
            value: 'Tedesco'
        },
    ]

    /**
     * @desc On close event
     * @returns {*}
     */
    const onClose = () => {
        props.onClose();
    };

    /**
     * @desc On submit
     * @param event
     * @returns {Promise<void>}
     */
    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true)
        try {
            if (props.actionType === 'store') {

                // Set question ID for set a children
                selectedQuestion.question_id = selectedQuestion['_id']
                await questionServices.storeQuestion(selectedQuestion);
            } else {
                await questionServices.updateQuestion(selectedQuestion['_id'], selectedQuestion);
            }
            EventBus.dispatch("fetchQuestions");
            onClose()
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        // If action is store delete all label fields for a clean creation
        if (props.question && props.isOpen && props.actionType === 'store') {
            setSelectedQuestion({
                ...props.question,
                label: {
                    en: ''
                }
            });
        } else { // Otherwise set full question data
            setSelectedQuestion(props.question)
        }
    }, [props.question, props.isOpen, props.actionType]);


    return (
        <>
            <Transition appear show={props.isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => onClose()}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25"/>
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel style={{background: '#E8E8E8'}}
                                              className="w-full max-w-md transform overflow-hidden rounded-2xl p-8 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="leading-6 text-gray-900 uppercase"
                                    >
                                        {props.actionType === 'store' ? 'AGGIUNGI ELEMENTO' : 'MODIFICA'}
                                    </Dialog.Title>
                                    <form onSubmit={handleSubmit} className="flex flex-col space-y-2 mt-8">
                                        {selectedQuestion &&
                                            languages.map(language => (
                                                <div key={language.key}
                                                     className="flex flex-col bg-white p-2 rounded-lg items-baseline space-y-1 w-full">
                                                    <label htmlFor={language.key}
                                                           className="block text-xs uppercase text-gray-700">
                                                        {language.value}
                                                    </label>
                                                    <ReactQuill
                                                        name={language.key}
                                                        id={language.key}
                                                        modules={modules}
                                                        theme="bubble"
                                                        className="w-full border border-gray-200 rounded-lg"
                                                        value={selectedQuestion.label[language.key]}
                                                        onChange={(e) => setSelectedQuestion({
                                                            ...selectedQuestion,
                                                            label: {
                                                                ...selectedQuestion.label,
                                                                [language.key]: e
                                                            }

                                                        })}
                                                    />
                                                    {}
                                                </div>
                                            ))
                                        }
                                        <div className="flex flex-row space-x-4 w-full justify-center mt-8">
                                            <button
                                                type="button"
                                                disabled={isLoading}
                                                onClick={() => onClose()}
                                                className="disabled:opacity-50 disabled:pointer-events-none inline-flex items-center text-center px-5 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-700"
                                            >
                                                Annulla
                                            </button>
                                            <button
                                                type="submit"
                                                disabled={isLoading}
                                                className="disabled:opacity-50 disabled:pointer-events-none inline-flex items-center text-center px-5 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-700"
                                            >
                                                Salva
                                            </button>

                                        </div>
                                    </form>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default EditQuestionDialog