import Question from "../../modules/dashboard/components/Question";
import ScrollContainer from "react-indiana-drag-scroll";

export default function FreeDashboardView({items,pos}) {
    return (
        <ScrollContainer hideScrollbars={false} className="relative inset-0 top-40 w-screen h-screen">
            <div
                className="flex flex-col w-full h-full transition-all duration-75 ease-linear"
                style={{
                    transformOrigin: "0 0",
                    transform: `translate(${pos.x}px, ${pos.y}px) scale(${pos.scale})`
                }}
            >
                <ul className="flex ">
                    <Question items={items}/>
                </ul>
            </div>
        </ScrollContainer>
    )
}
