const InfiniteProgressBar = () => {
    return (
        <div className="w-full absolute top-0 left-0 right-0 z-50 w-full" style={{zIndex: 100}}>
            <div className="animate-pulse flex">
                <div className="flex-1">
                    <div className="h-1 bg-green-600"></div>
                </div>
            </div>
        </div>
    )
}

export default InfiniteProgressBar