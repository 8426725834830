/* eslint-disable max-len */
import Axios from 'axios';
import EventBus from "../utility/EventBus";
const api = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        'X-PLATFORM': 'ADMIN',
    },
});

api.interceptors.request.use(
    (config) => {
        const tokenString = sessionStorage.getItem('token');
        const parsedToken = JSON.parse(tokenString);
        const token = 'Bearer '+ parsedToken
        if (token) {
            config.headers.Authorization = `${token}`;
        }
        EventBus.dispatch('isGlobalLoading',true)
        return config;
    },
    (error) => {

        EventBus.dispatch('isGlobalLoading',false)
        if (
            error.response.status === 401 ||
            error.response.data.message === 'Unauthorized'
        ) {
            // Remove token and user data
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('user')
            // eslint-disable-next-line no-restricted-globals
            window.location.replace('/login')
        }
        return Promise.reject(error.response.data);
    },
);

api.interceptors.response.use(
    (response) => {
        EventBus.dispatch('isGlobalLoading',false)
        return response
    },
    (error) => {
        EventBus.dispatch('isGlobalLoading',false)
        const errorData = error.response.data
        if ('errors' in errorData) {
            const errorsList = Object.values(errorData.errors);
            errorsList.flat().forEach(errorMessage => {
                // Messaggio di errore
            })
        } else {
            // Messaggio di errore con error.response.data.message
        }
        if (
            (error.response && error.response.status === 401) ||
            !error.response ||
            !error.response.status ||
            error.response.data.message === 'Unauthorized'
        ) {
            // Remove token and user data
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('user')
            // eslint-disable-next-line no-restricted-globals
            window.location.replace('/login')
        }
        return Promise.reject(error.response ? error.response : error);
    },
);

export default api;
