import Api from "../../../plugins/api";

export default {
    /**
     * @desc Attempt login
     * @param user
     * @returns {Promise<*>}
     */
    async login(user) {
        return (await Api.post(`/login`, user)).data;
    },

    /**
     * @desc Attempt Logout
     * @returns {Promise<*>}
     */
    async logout() {
        return (await Api.get('/logout'));
    },
};