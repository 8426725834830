import Auth from "../../../layouts/Auth";
import {useState} from "react";
import authServices from "../services/auth.services";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

function Login() {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const {token, setToken, setUser} = useAuth();
    /**
     * @desc On submit
     * @param event
     * @returns {Promise<void>}
     */
    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true)
        try {
            const dataObject = {email, password}

            let response = await authServices.login(dataObject);
            // Update data in store
            setToken(response.data.access_token)
            setUser(response.data.user)
            // Set token in store
            // Goto to dashboard
            //navigate(from, { replace: true });
            //await router.push({path: '/accommodations'});

        } catch (error) {
            // Reset store for safety reasons
            setToken(null)
        } finally {
            setIsLoading(false)
        }
    }

    if(token) {
        return <Navigate to="/dashboard" replace={true} />
    }

    return (
        <>

            <Auth title="Klover Chatbot Admin">
                <form onSubmit={handleSubmit} className="space-y-6">
                    {isLoading}
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Email
                        </label>
                        <div className="mt-1">
                            <input
                                disabled={isLoading}
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                onChange={e => setEmail(e.target.value)}
                                className="disabled:bg-gray-100 disabled:pointer-events-none appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                            Password
                        </label>
                        <div className="mt-1">
                            <input
                                disabled={isLoading}
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                onChange={e => setPassword(e.target.value)}
                                className="disabled:bg-gray-100 disabled:pointer-events-none appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                    </div>

                    {/*<div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <input
                                id="remember-me"
                                name="remember-me"
                                disabled={isLoading}
                                type="checkbox"
                                className="disabled:bg-gray-100 disabled:pointer-events-none h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                            />
                            <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                Ricordami
                            </label>
                        </div>

                        <div className="text-sm">
                            <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                                Forgot your password?
                            </a>
                        </div>
                    </div>*/}

                    <div>
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="disabled:opacity-50 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Sign in
                        </button>
                    </div>
                </form>
            </Auth>

        </>
    )
}

export default Login