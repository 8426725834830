const Main = ({children}) => {
    return (
        <main className="">
            <div className="mx-auto py-6">
                <div className="py-6 sm:px-0">
                    {children}
                </div>
            </div>
        </main>
    )
}

export default Main
