import authApi from './auth.api';

export default {

    /**
     * @desc Attempt login
     * @param user
     * @returns {Promise<void>}
     */
    async login(user) {
        return await authApi.login(user);
    },
    /**
     * @desc Make logout
     * @returns {Promise<void>}
     */
    async logout() {
        try {
            await authApi.logout();
        } catch (error) {
            console.error(error)
        } finally {
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('user')
        }
    },
};
