import Question from "../../modules/dashboard/components/Question";
import {useEffect, useState} from "react";
import {ArrowLongLeftIcon} from '@heroicons/react/20/solid'

export default function DefaultDashboardView({items, pos}) {
    const [currentQuestionId, setCurrentQuestionId] = useState(null)

    /**
     * @desc on mounted
     */
    useEffect(() => {
        if (!currentQuestionId) {
            setCurrentQuestionId(items[0]['_id'])
        }
    }, [currentQuestionId, items]);

    /**
     * @desc On child select callback
     * @returns {*}
     */
    const onChildSelect = (id) => {
        setCurrentQuestionId(id)
    };

    /**
     * @desc Get current question
     * @returns {null|*}
     */
    const getCurrentQuestion = () => {

        const found = getSubMenuItem(items, currentQuestionId);
        if (found) {
            return found
        } else {
            return null
        }
    }

    /**
     * Find item recursively
     * @param subMenuItems
     * @param id
     * @returns {*}
     */
    const getSubMenuItem = (subMenuItems, id) => {
        if (subMenuItems) {
            for (let i = 0; i < subMenuItems.length; i++) {
                if (subMenuItems[i]['_id'] === id) {
                    return subMenuItems[i];
                }
                let found = getSubMenuItem(subMenuItems[i].children, id);
                if (found) return found;
            }
        }
    };
    return (
        <>
            {(getCurrentQuestion() && getCurrentQuestion().question_id) &&
                <button
                    type="button"
                    onClick={() => onChildSelect(getCurrentQuestion().question_id)}
                    className="inline-flex fixed top-36 left-6 items-center z-50 rounded-md border border-transparent bg-gray-800 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                    <ArrowLongLeftIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true"/>
                    Indietro
                </button>
            }
            <div className="absolute inset-0 top-40 w-screen h-screen m-0 overflow-y-auto overflow-x-hidden">
                {currentQuestionId &&
                    <>
                        <Question items={[getCurrentQuestion()]} printChildren={false}/>
                        {(getCurrentQuestion() && getCurrentQuestion().children.length) &&
                            <Question key={getCurrentQuestion().children[0]['_id']}
                                      items={[getCurrentQuestion().children[0]]}
                                      onChildSelect={onChildSelect}
                                      printChildren={false}/>
                        }

                    </>
                }
            </div>
        </>
    )
}
