import Api from "../../../plugins/api";

export default {

    /**
     * @desc Get question
     * @param id
     * @returns {Promise<*>}
     */
    async getQuestion(id) {
        return (await Api.get(`/questions/${id}`)).data;
    },

    /**
     * @desc Get questions
     * @param query
     * @returns {Promise<*>}
     */
    async getQuestions(query) {
        return (await Api.get(`/questions?${query}`)).data;
    },

    /**
     * @desc Update question
     * @param id
     * @param payload
     * @returns {Promise<*>}
     */
    async updateQuestion(id, payload) {
        return (await Api.patch(`/questions/${id}`, payload)).data;
    },

    /**
     * @desc Store question
     * @param payload
     * @returns {Promise<*>}
     */
    async storeQuestion(payload) {
        return (await Api.post(`/questions`, payload)).data;
    },

    /**
     * @desc Delete question
     * @returns {Promise<*>}
     * @param id
     */
    async deleteQuestion(id) {
        return (await Api.delete(`/questions/${id}`)).data;
    },
};