import styles from '../../../styles/Question.module.css'
import {useState} from "react";
import EditQuestionDialog from "./EditQuestionDialog";
import DeleteQuestionDialog from "./DeleteQuestionDialog";
import parse from 'html-react-parser';

function Question({items, printChildren = true, onChildSelect}) {

    let [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
    let [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    let [selectedQuestion, setSelectedQuestion] = useState(null)
    let [actionType, setActionType] = useState('update')


    /**
     * @desc On question click handler
     * @returns {*}
     */
    const onQuestionClick = (question, actionType) => {
        setSelectedQuestion(question)
        setActionType(actionType)
        setIsEditDialogOpen(true)
    };

    /**
     * @desc On question click handler
     * @returns {*}
     */
    const onChildClick = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.classList.add('animate-pulse')
            element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'});
            setTimeout(() => {
                element.classList.remove('animate-pulse')
            }, 2000)
        }

        if (onChildSelect) {
            onChildSelect(id)
        }
    };

    /**
     * @desc On question Delete
     * @returns {*}
     */
    const onQuestionDelete = (question) => {
        setSelectedQuestion(question)
        setIsDeleteDialogOpen(true)
    };

    /**
     * @desc On is edit dialog close handler
     * @param e
     */
    const onIsEditDialogClose = (e) => {
        setIsEditDialogOpen(false)
    }

    /**
     * @desc On is delete dialog close
     * @param e
     */
    const onIsDeleteDialogClose = (e) => {
        setIsDeleteDialogOpen(false)
    }


    return (
        <>
            <ul className={styles.list}>
                {items.map(question => (
                    <li key={question['_id']} className={styles.item}>
                        <div className="flex m-auto question-container" id={question['_id']}
                             style={{width: "325px", scrollMargin: "200px"}}>
                            <div
                                className="m-auto w-full flex flex-col p-4 rounded-lg text-left text-white"
                                style={{background: question.question_id ? '#535353' : '#1C1C1C'}}>
                                <div className="flex flex-row justify-between w-full text-gray-400">

                                    {question.question_id &&
                                        <small>DOMANDA</small>
                                    }

                                    {!question.question_id &&
                                        <small>DOMANDA DI BENVENUTO</small>
                                    }
                                    <div className="flex flex-row items-center justify-center space-x-2 text-sm">
                                        {question.question_id &&
                                            <button
                                                onClick={() => onQuestionDelete(question)}
                                                type="button"
                                                className="inline-flex items-center text-center border-transparent underline bg-transparent hover:text-white"
                                            >
                                                Elimina
                                            </button>
                                        }
                                        <button
                                            onClick={() => onQuestionClick(question, 'update')}
                                            type="button"
                                            className="inline-flex items-center text-center border-transparent underline bg-transparent hover:text-white"
                                        >
                                            Modifica
                                        </button>
                                    </div>
                                </div>
                                {/*Label*/}
                                <span className="line-clamp-3 text-ellipsis">{parse(question.label['it'])}</span>
                                {/*Children*/}
                                {question.children.length > 0 &&
                                    <div className="flex flex-col space-y-2 mt-4">
                                        <small className="text-xs">RISPOSTE</small>
                                        {question.children.map(child =>
                                            <button key={child['_id']}
                                                    onClick={() => onChildClick(child['_id'])}
                                                    className="flex rounded-lg p-2 text-gray-800 bg-gray-300 hover:bg-teal-500">
                                            <span
                                                className="line-clamp-2">{parse(child.label['it'])}</span>
                                            </button>
                                        )
                                        }
                                    </div>
                                }
                                <div className="mt-4 flex justify-center">
                                    <button
                                        type="button"
                                        onClick={() => onQuestionClick(question, 'store')}
                                        className="inline-flex items-center text-center px-5 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-700"
                                    >
                                        Aggiungi opzioni
                                    </button>
                                </div>
                            </div>
                        </div>
                        {(question.children.length > 0 && printChildren) &&
                            <Question items={question.children}/>
                        }
                    </li>
                ))}
            </ul>
            <EditQuestionDialog question={selectedQuestion} isOpen={isEditDialogOpen}
                                onClose={onIsEditDialogClose} actionType={actionType}/>
            <DeleteQuestionDialog question={selectedQuestion} isOpen={isDeleteDialogOpen}
                                  onClose={onIsDeleteDialogClose}/>
        </>
    )
}

export default Question