import {useCallback, useEffect, useState} from "react";
import questionServices from "../services/question.services";
import Header from "../../../components/layout/Header";
import EventBus from "../../../utility/EventBus";
import {Switch} from '@headlessui/react'
import DefaultDashboardView from "../../../components/dashboard/DefaultDashboardView";
import FreeDashboardView from "../../../components/dashboard/FreeDashboardView";

export default function Dashboard() {

    const [questions, setQuestions] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [isFirstCentered, setIsFirstCentered] = useState(false);
    const [searchText, setSearchText] = useState(null)
    const [pos, setPos] = useState({x: 0, y: 0, scale: 1});
    const [defaultView, setDefaultView] = useState(true)
    /**
     * @desc Get questions
     * @type {(function(): Promise<void>)|*}
     */
    const getQuestions = useCallback(async () => {
        if (!questions && !questions.length) {
            setIsLoading(true)
        }
        try {
            let qs = searchText ? `text=${searchText}` : null
            let response = await questionServices.getQuestions(qs);
            const questionsRetrieved = [response.data]
            setQuestions(questionsRetrieved)
        } catch (error) {
            // Reset store for safety reasons
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }, [searchText])

    /**
     * @desc on mounted
     */
    useEffect(() => {
        (async () => {
            if (!isFirstCentered && questions.length) {
                await onCenterCanvas()
                setIsFirstCentered(true)
            }
        })();
    }, [questions]);

    /**
     * @desc Listen for events
     */
    useEffect(() => {
        EventBus.on("fetchQuestions", (data) =>
            getQuestions()
        );
        getQuestions().catch(console.error)
    }, [getQuestions]);

    /**
     * @desc on mounted
     */
    useEffect(() => {
        (async () => {
            if (!defaultView) {
                await onCenterCanvas()
            }
        })();
    }, [defaultView]);

    /**
     * @desc Get element by id until is present on screen
     * @param id
     * @returns {Promise<unknown>}
     */
    const getElementByIdAsync = id => new Promise(resolve => {
        const getElement = () => {
            const element = document.getElementById(id);
            if (element) {
                resolve(element);
            } else {
                requestAnimationFrame(getElement);
            }
        };
        getElement();
    });


    /**
     * @desc Handle search text
     * @returns {Promise<void>}
     */
    const onCenterCanvas = async () => {
        const element = await getElementByIdAsync(questions[0]['_id'])
        element.classList.add('animate-pulse')
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'});
        setTimeout(() => {
            element.classList.remove('animate-pulse')
        }, 2000)
        element.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'})
    };

    /**
     * @desc Handle search text
     * @param event
     * @returns {Promise<void>}
     */
    const handleSearchText = async event => {
        setSearchText(event.target.value)
        await getQuestions()
    };

    /**
     * @desc Handle zoom page
     * @param event
     * @returns {Promise<void>}
     */
    const handleZoomChange = async event => {
        setPos({
            ...pos,
            scale: event.target.value
        });
        const centeredElement = document.elementFromPoint(
            document.body.offsetWidth / 2, document.body.offsetHeight / 2
        ).closest(".question-container");
        if(centeredElement){
            centeredElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'});
        }
    };

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <>
            <Header>
                <div className="flex flex-row w-full items-center justify-between">
                    {/*<div>
                        <div className="relative flex items-center">
                            <input
                                type="text"
                                name="search"
                                id="search"
                                placeholder="Cerca domande..."
                                onChange={handleSearchText}
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                            />
                            <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                                <kbd
                                    className="inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400">
                                    ⌘K
                                </kbd>
                            </div>
                        </div>
                    </div>*/}

                    <Switch.Group as="div" className="flex items-center">
                        <Switch
                            checked={defaultView}
                            onChange={setDefaultView}
                            className={classNames(
                                defaultView ? 'bg-indigo-600' : 'bg-gray-200',
                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                            )}
                        >
        <span
            aria-hidden="true"
            className={classNames(
                defaultView ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
        />
                        </Switch>
                        <Switch.Label as="span" className="ml-3">
                            <span
                                className="text-sm font-medium text-gray-900">Visualizzazione {defaultView ? 'standard' : 'libera'}</span>
                            <span className="text-sm text-gray-500"></span>
                        </Switch.Label>
                    </Switch.Group>
                    {!defaultView &&
                        <div className="flex flex-row items-center justify-center space-x-4">
                            <div className="mt-1 flex flex-row items-center space-x-2">
                                <div className=" inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500 sm:text-sm" id="zoom">Zoom</span>
                                </div>
                                <input
                                    type="range"
                                    name="zoom"
                                    id="zoom"
                                    step={0.1}
                                    min={0.1}
                                    max={1}
                                    onChange={handleZoomChange}
                                    value={pos.scale}
                                    disabled={isLoading}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md disabled:bg-gray-200"
                                    placeholder="0.00"
                                    aria-describedby="zoom"
                                />

                            </div>
                            <button
                                onClick={onCenterCanvas}
                                type="button"
                                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                Centra visuale
                            </button>
                        </div>
                    }
                </div>
            </Header>
            {!isLoading &&
                <>
                    {!defaultView &&
                        <FreeDashboardView items={questions} pos={pos}/>
                    }

                    {defaultView &&
                        <DefaultDashboardView items={questions} pos={pos}/>
                    }
                </>
            }
        </>
    )
}

