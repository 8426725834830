import "./App.css"
import Master from "./layouts/Master"
import { Outlet } from "react-router-dom"
import InfiniteProgressBar from "./components/common/InfiniteProgressBar"
import EventBus from "./utility/EventBus"
import { useEffect, useState } from "react"

function App() {
  let [isGlobalLoadingVisible, setIsGlobalLoadingVisible] = useState(false)

  useEffect(() => {
    // Show global loader
    EventBus.on("isGlobalLoading", (data) => setIsGlobalLoadingVisible(data))

    // Append chatbot script
    const script = document.createElement("script")
    script.id = "chatbot-script"
    script.src = `${process.env.REACT_APP_CHATBOT_BASE_URL}/js/embed.js`
    script.async = false
    script.setAttribute("data-url", "https://chatbot.klover.it/")
    script.setAttribute("data-locale", "it")
    document.body.appendChild(script)
  }, [])

  return (
    <>
      {isGlobalLoadingVisible && <InfiniteProgressBar />}

      <Master>
        <Outlet />
      </Master>
    </>
  )
}

export default App
