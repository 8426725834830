import Toolbar from "../components/layout/Toolbar";
import Main from "../components/layout/Main";

const Master = ({children}) => {
    return (
        <>
            <div className="min-h-full">
                <Toolbar/>
                <Main>
                    {children}
                </Main>
            </div>
        </>
    )
}

export default Master