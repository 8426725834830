import {Dialog, Transition} from "@headlessui/react";
import {Fragment, useState, useEffect} from "react";
import questionServices from "../services/question.services";
import EventBus from "../../../utility/EventBus";

function DeleteQuestionDialog(props) {
    let [selectedQuestion, setSelectedQuestion] = useState(null)
    let [isLoading, setIsLoading] = useState(false)

    /**
     * @desc On close event
     * @returns {*}
     */
    const onClose = () => {
        props.onClose();
    };

    /**
     * @desc On confirm deletion
     * @returns {Promise<void>}
     */
    const onConfirm = async () => {
        setIsLoading(true)
        try {
            await questionServices.deleteQuestion(selectedQuestion['_id']);
            EventBus.dispatch("fetchQuestions");
            onClose()
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setSelectedQuestion(props.question)
    }, [props.question]);


    return (
        <>
            <Transition appear show={props.isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => onClose()}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25"/>
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel style={{background: '#E8E8E8'}}
                                              className="w-full max-w-md transform overflow-hidden rounded-2xl px-4 py-8 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="leading-6 text-gray-900 uppercase"
                                    >
                                        Elimina
                                    </Dialog.Title>
                                    <div className="flex flex-col space-y-8 mt-8">
                                        <div>
                                            <span>Sei sicuro di voler eliminare questo elemento?</span>
                                        </div>
                                        <div className="flex flex-row space-x-4 w-full justify-center">
                                            <button
                                                type="button"
                                                disabled={isLoading}
                                                onClick={() => onClose()}
                                                className="disabled:opacity-50 disabled:pointer-events-none inline-flex items-center text-center px-5 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-700"
                                            >
                                                Annulla
                                            </button>
                                            <button
                                                type="button"
                                                disabled={isLoading}
                                                onClick={() => onConfirm()}
                                                className="disabled:opacity-50 disabled:pointer-events-none inline-flex items-center text-center px-5 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-700"
                                            >
                                                Elimina
                                            </button>

                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default DeleteQuestionDialog